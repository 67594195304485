
















import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import HomeworkFormTemplate from '@/components/homework/HomeworkFormTemplate.vue';
import { UpdateHomeworkItem, UpdateHomeworkItemForAxios } from '@/models/homework';
import { DropdownItem } from '@/models/dropdown';
import { CourseStores } from '@/store/course';
import { HomeworkStores } from '@/store/homeworks';
import moment from 'moment';
import { convertDateToDateAnd12HourFormat } from '@/utils/moment';
@Component({
    components: { Page, BackButtonLayout, HomeworkFormTemplate },
})
export default class EditHomework extends Vue {
    currentCourses: DropdownItem[] = [];
    courseListStore = CourseStores.list;
    homeworkDetailStore = HomeworkStores.detail;
    homeworkStateStore = HomeworkStores.state;
    courseStartDate: Date | null = null;
    courseEndDate: Date | null = null;

    currentHomeworkDetails: UpdateHomeworkItem = {
        id: -1,
        name: ``,
        startDate: new Date(),
        deadline: new Date(),
        courseId: -1,
        availability: false,
        sku: ``,
        tuitionId: 1,
        description: ``,
    };

    get layoutTitle() {
        return `Edit Current Homework`;
    }

    get courses() {
        return this.currentCourses;
    }

    get showTemplate() {
        return this.courses.length > 0 && this.currentHomeworkDetails.name.length > 0;
    }

    mounted() {
        // this.courseListStore.retrieveCoursesWithParams({});
        this.courseListStore.retrieveAllSiteCoursesWithParams({});
        this.homeworkDetailStore.fetchHomeworkById(this.$route.params.id);
    }

    getDateRange(courseId: number) {
        const courseIndex = this.courseListStore.response!.objects.findIndex((data) => {
            return data.id === courseId;
        });
        if (courseIndex > -1) {
            const data = this.courseListStore.response!.objects[courseIndex];
            this.courseStartDate = moment(data.startDate).toDate();
            this.courseEndDate = moment(data.startDate).add(data.duration.months , `months`).add(data.duration.weeks, `weeks`).toDate();
        }
    }

    @Watch(`courseListStore.response`)
    parseCourseData() {
        if (this.courseListStore.response) {
            this.currentCourses = [];
            this.courseListStore.response.objects.forEach((data) => {
                const endDate = moment(data.startDate).add(data.duration.months , `months`).add(data.duration.weeks, `weeks`).toDate();
                this.currentCourses.push({
                    text: `${data.courseName} ( ${ convertDateToDateAnd12HourFormat(data.startDate) } - ${convertDateToDateAnd12HourFormat(endDate)} )`,
                    value: data.id.toString(),
                    uniqueKey: `data_${data.courseName}`
                });
            });
        }
    }

    @Watch(`homeworkDetailStore.currentHomework`)
    parseHomeworkData() {
        if (this.homeworkDetailStore.currentHomework) {
            this.currentHomeworkDetails = {
                ...this.homeworkDetailStore.currentHomework,
                startDate: moment(this.homeworkDetailStore.currentHomework.startDate).toDate(),
                deadline: moment(this.homeworkDetailStore.currentHomework.deadline).toDate(),
                availability: this.homeworkDetailStore.currentHomework.availability === 1 ? true : false,
            };
        }
    }

    updateCurrentHomework(data: UpdateHomeworkItem) {
        const payload: UpdateHomeworkItemForAxios = {
            ...data,
            startDate: moment(data.startDate).format(`YYYY-MM-DD`),
            deadline: moment(data.deadline).format(`YYYY-MM-DD`),
            availability: data.availability ? 1 : 0,
        };
        this.homeworkDetailStore.updateHomework(payload);
    }

    @Watch(`homeworkDetailStore.response`)
    redirectToHomework() {
        if (this.homeworkDetailStore.response) {
            this.homeworkStateStore.updateHomeworkIsModified(true);
            this.$router.push({
                path: `/homeworks`
            });
        }
    }

}
